import React, { useContext, useEffect, useState } from "react";
import { ProjectType, Task } from "../../../data/Interfaces";
import { OneTask } from "../../Pages/Project/OneTask";
import { deleteTaskById } from "../../../client/client";
import { ProjectContext } from "../../../context/ProjectContext";
export interface FinishedTasksProps {
  selectedTask: string;
  setSelectedTask: (str: string) => void;
}

export const FinishedTasks = ({
  selectedTask,
  setSelectedTask,
}: FinishedTasksProps) => {
  const { tasks } = useContext(ProjectContext);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(tasks.filter((t) => t.state === "finished").length);
  }, [tasks]);

  return (
    <div className="standard-container project-standard-container taller">
      <div className="standard-container-title">
        <h1>Completed tasks</h1>
      </div>
      {counter === 0 ? (
        <div className="no-task-yet">Complete your first task</div>
      ) : (
        <div className="project-tasksTodo-container">
          {tasks
            .filter((t) => t.state === "finished")
            .map((t, index) => {
              return (
                <OneTask setSelected={setSelectedTask} task={t} key={index} />
              );
            })}
        </div>
      )}
      <button className="standard-container-button medium right noHover">
        {counter} {counter == 1 ? "Task" : "Tasks"}
      </button>
    </div>
  );
};
